<template>
  <!--账单查询 -->
  <div class="equipmentStatis">
    <div class="datarow">
      <div class="block">
        <span class="demonstration">日期</span>
        <el-date-picker
          v-model="datevalue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
    </div>
    <div style="text-align:right;margin-bottom:10px">
      <el-button type="success" size="mini" @click="search()">查询</el-button>
      <el-button type="primary" size="mini" @click="reset()">重置</el-button>
    </div>
    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%;max-height:65vh;overflow-Y:scroll" @row-click="rowclick">
      <el-table-column prop="user_name" label="用户名"></el-table-column>
      <el-table-column prop="head_pic" label="头像">
        <template #default="scope">
          <img
            :src="scope.row.head_pic"
            alt=""
            style="width:50px;height:50px;border-radius:50%"
            v-if="scope.row.head_pic"
          />
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="电话号码"></el-table-column>
      <el-table-column prop="created_at" label="下单时间"></el-table-column>
      <el-table-column prop="rjf_points" label="余额扣减"></el-table-column>
      <el-table-column prop="package_price" label="支付金额"></el-table-column>
      <el-table-column label="支付状态">
        <template #default="scope">
          <span>{{ scope.row.pay_status == 1 ? "已支付" : "未支付" }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { orderStatus } from "../../request/api";
import { defineComponent, ref, watch } from "vue";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      datevalue: ["", ""], //日期
      tableData: [],
      pageTotal: "",
    };
  },
  methods: {
    rowclick(row) {
      this.$router.push("/Userdetail?id=" + row.user_id);
    },
    list() {
      return new Promise((resolve, reject) => {
        orderStatus({
          page: this.page,
          page_size: this.pagepage,
          start_time: this.datevalue[0],
          end_time: this.datevalue[1],
        }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.tableData = res.list.list;
          this.device_list = res.list.device_list;
        });
      });
    },
    getTime(time) {
      let date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    async reset() {
      this.addressvalue = "";
      this.datevalue = "";
      await this.list();
      this.$emit("pagetotalClick", this.pageTotal);
    },
    async search() {
      if (this.datevalue[0]) {
        this.datevalue[0] = this.getTime(this.datevalue[0]);
        this.datevalue[1] = this.getTime(this.datevalue[1]);
      }

      await this.list();
      // this.$emit("pagetotalClick", this.pageTotal);
    },
  },
  async created() {
    this.pageTotal = await this.list();
    //  this.pageTotal?this.$emit("pagetotalClick", this.pageTotal):'';
  },
  watch: {
    page() {
      this.list();
    },
    pagepage() {
      this.list();
    },
  },
};
</script>
<style lang="less" scoped>
.datarow {
  display: flex;
  justify-content: space-between;
}
/deep/.has-gutter th {
  background-color: #f5f7fa;
}

/deep/.cell {
  text-align: center;
}
.yesonmon {
  color: #3f7ee2;
  line-height: 80px;
  text-align: center;
}
.datarow {
  padding: 20px 0;
  .demonstration {
    margin-right: 10px;
  }
}
</style>
